@font-face {
    font-family: 'Lato';
    src: url('./assets/font/Lato-Black.svg');
    src: local('Lato Regular'), local('Lato-Regular'), url('./assets/font/Lato-Regular.eot?#iefix') format('embedded-opentype'), url('./assets/font/Lato-Regular.woff2') format('woff2'), url('./assets/font/Lato-Regular.woff') format('woff'), url('./assets/font/Lato-Regular.ttf') format('truetype'), url('./assets/font/Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('./assets/font/Lato-Black.eot');
    src: local('Lato Black'), local('Lato-Black'), url('./assets/font/Lato-Black.eot?#iefix') format('embedded-opentype'), url('./assets/font/Lato-Black.woff2') format('woff2'), url('./assets/font/Lato-Black.woff') format('woff'), url('./assets/font/Lato-Black.ttf') format('truetype'), url('./assets/font/Lato-Black.svg#Lato-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('./assets/font/Lato-Bold.eot');
    src: local('Lato Bold'), local('Lato-Bold'), url('./assets/font/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('./assets/font/Lato-Bold.woff2') format('woff2'), url('./assets/font/Lato-Bold.woff') format('woff'), url('./assets/font/Lato-Bold.ttf') format('truetype'), url('./assets/font/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('./assets/font/Lato-Italic.eot');
    src: local('Lato Italic'), local('Lato-Italic'), url('./assets/font/Lato-Italic.eot?#iefix') format('embedded-opentype'), url('./assets/font/Lato-Italic.woff2') format('woff2'), url('./assets/font/Lato-Italic.woff') format('woff'), url('./assets/font/Lato-Italic.ttf') format('truetype'), url('./assets/font/Lato-Italic.svg#Lato-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

$primary-color: #D42292;
$secondary-color: #3bb4ad;
$tertiary-color: #c62c36;
$gray-color: #d7d7d7;
body {
    font-family: 'Lato' !important;
    font-style: normal;
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato' !important;
    font-weight: bold;
    font-style: normal;
    span {
        color: $tertiary-color;
    }
}

h1 {
    @media (min-width: 992px) {
        font-size: 56px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 50px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 44px;
    }
    @media (max-width: 575px) {
        font-size: 38px;
    }
}

h2 {
    @media (min-width: 992px) {
        font-size: 38px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 36px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 34px;
    }
    @media (max-width: 575px) {
        font-size: 32px;
    }
}

h3 {
    @media (min-width: 992px) {
        font-size: 24px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 23px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 22px;
    }
    @media (max-width: 575px) {
        font-size: 21px;
    }
}

h4 {
    @media (min-width: 992px) {
        font-size: 20px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 19px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 18px;
    }
    @media (max-width: 575px) {
        font-size: 17px;
    }
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

p {
    font-size: 16px;
}

nav.bg-light {
    .logo {
        max-height: 80px;
        @media (max-width: 991px) {
            max-height: 60px;
        }
    }
    .link-dark {
        text-transform: uppercase;
        &.active {
            border-bottom: 3px solid $primary-color;
        }
    }
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
    .navbar-collapse {
        @media (min-width: 992px) {
            margin-top: 47px;
        }
    }
}

.bg-pink {
    background-color: $primary-color;
    color: white;
    text-align: center;
    .altura {
        // height: 600px;
        @media (min-width: 768px) {
            height: 600px;
        }
        @media (min-width: 576px) and (max-width: 767px) {
            height: 100vw;
        }
        @media (min-width: 470px) and (max-width: 575px) {
            height: 120vw;
        }
        @media (max-width: 469px) {
            height: 150vw;
        }
    }
}

.bg-black {
    background-color: black;
    background-image: url('./assets/img/bg_black.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-align: center;
}

.bg-white {
    background-color: white;
}

.bg-friends {
    background-image: url('./assets/img/banner_02.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.gb-line {
    background-color: $secondary-color;
    height: 3px;
    width: 60px;
}

.start-40 {
    left: 40%!important;
}

.banner-01 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @media (min-width: 992px) {
        height: calc(100vh - 90px);
    }
    @media (max-width: 991px) {
        height: calc(100vh - 70px);
    }
    .btn-wp {
        border: 3px solid white;
        color: white;
        padding: 10px;
        position: absolute;
        text-decoration: none;
    }
    @media (min-width: 640px) {
        background-image: url('./assets/img/banner_01.jpg');
        .btn-wp {
            bottom: 180px;
            right: 140px;
        }
    }
    @media (max-width: 639px) {
        background-image: url('./assets/img/banner_01-movil.jpg');
        .btn-wp {
            bottom: 120px;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            width: 165px;
        }
    }
}

.banner-preparacion {
    background-image: url('./assets/img/banner_03.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 992px) {
        height: 330px;
    }
    @media (max-width: 991px) {
        height: 150px;
    }
}

.banner-conoce {
    background-image: url('./assets/img/banner_05.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 992px) {
        height: 330px;
    }
    @media (max-width: 991px) {
        height: 150px;
    }
}

.banner-mezcal {
    background-image: url('./assets/img/banner_06.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 992px) {
        height: 330px;
    }
    @media (max-width: 991px) {
        height: 150px;
    }
}

.banner-pink-img {
    background-image: url('./assets/img/bg_pink.jpg');
}

.banner-red-img {
    background-image: url('./assets/img/bg_red.jpg');
}

.banner-cyan-img {
    background-image: url('./assets/img/bg_cyan.jpg');
}

.banner-pink-img,
.banner-red-img,
.banner-cyan-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.col-img {
    @media (min-width: 768px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

// Form
input,
textarea {
    border: 2px solid $gray-color;
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
}

::placeholder {
    color: $gray-color;
}

.contacto {
    h3 {
        font-weight: normal;
    }
    .mail {
        a {
            color: $primary-color;
            font-weight: bold;
            text-decoration: none;
        }
    }
    .link {
        a {
            color: black;
            text-decoration: none;
        }
    }
    img {
        &.social {
            max-width: 30px;
        }
    }
}

button {
    &.green,
    &.red {
        border: 3px solid white;
        color: white;
        font-size: 12px;
        padding: 5px 25px;
        width: 160px;
    }
    &.green {
        background-color: $secondary-color;
    }
    &.red {
        background-color: $tertiary-color;
    }
}

.mezcal {
    h1 {
        color: $tertiary-color;
        font-weight: 900;
        margin-bottom: 30px;
    }
    h3 {
        font-weight: normal;
    }
    .line {
        background-color: $tertiary-color;
        width: 70px;
        height: 3px;
        margin-bottom: 30px;
    }
}

footer {
    background-color: black;
    color: white;
    text-align: center;
    p {
        font-size: 12px;
        margin: 0;
        padding: 0;
        a {
            color: white;
            text-decoration: none;
            &:hover {
                color: $secondary-color;
            }
        }
    }
}

.wrap-modal {
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    top: 0;
}

.modal-year {
    background-color: white;
    background-image: url('./assets/img/bg_white.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 40px;
    height: 80vh;
    width: 80vw;
    z-index: 1000;
    overflow: auto;
    @media (min-width: 768px) {
        max-height: 700px;
    }
    h3 {
        padding-left: 20px;
        padding-right: 20px;
    }
    p {
        padding-left: 40px;
        padding-right: 40px;
    }
    img {
        @media (min-width: 470px) and (max-width: 575px) {
            width: 120px;
        }
        @media (max-width: 469px) {
            width: 100px;
        }
    }
    button {
        background-color: transparent;
        border: 3px solid $tertiary-color;
        color: black;
        margin-top: 10px;
        padding: 10px;
        width: 180px;
    }
}

.wrap-contact {
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    top: 0;
}

.modal-contact {
    background-color: white;
    background-image: url('./assets/img/bg_white.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 40px;
    height: 80vh;
    width: 80vw;
    z-index: 1000;
    overflow: auto;
    @media (min-width: 768px) {
        max-height: 550px;
    }
    .close {
        position: absolute;
        top: 10px;
        right: 15px;
    }
}